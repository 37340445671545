<template>
  <div>
    <div class="centerceont">
      <div class="acvterto">
        <div class="acavtor">
          <img v-if="form.avatar" alt="" :src="form.avatar" />
          <img
            v-else
            alt=""
            src="https://cloud.cdmp.tech/bac495dcd2b71dfeea4a346159eccfb0.png"
          />
        </div>
        <div class="acvter">
          <div class="aczh">账号：{{ form.username }}</div>
          <div class="aczh">
            <div v-if="!edit_name">
              <span>用户名：{{ form.name }}</span>
              <i class="el-icon-edit" @click="edit_name = true"></i>
            </div>
            <div v-else class="changePx">
              <el-input v-model="form.name"></el-input>
              <i class="el-icon-check" @click="editP(6)"></i>
            </div>
          </div>
        </div>
      </div>

      <div class="detilrexop">
        <el-form ref="form" :model="form" label-width="200px">
          <el-form-item label="单位：">
            <div v-if="!edit_company">
              <span> {{ form.company }}</span>
              <i class="el-icon-edit" @click="edit_company = true"></i>
            </div>
            <div v-else class="changePx">
              <el-input v-model="form.company"></el-input>
              <i class="el-icon-check" @click="editP(1)"></i>
            </div>
          </el-form-item>
          <el-form-item label="专业：">
            <div v-if="!edit_domain">
              <span>{{ form.domain }}</span>
              <i class="el-icon-edit" @click="edit_domain = true"></i>
            </div>
            <div v-else class="changePx">
              <el-input v-model="form.domain"></el-input>
              <i class="el-icon-check" @click="editP(2)"></i>
            </div>
          </el-form-item>
          <el-form-item label="职称：">
            <div v-if="!edit_professional_title">
              <span> {{ form.professional_title }}</span>
              <i
                class="el-icon-edit"
                @click="edit_professional_title = true"
              ></i>
            </div>
            <div v-else class="changePx">
              <el-input v-model="form.edit_professional_title"></el-input>
              <i class="el-icon-check" @click="editP(3)"></i>
            </div>
          </el-form-item>
          <el-form-item label="联系电话：">
            <div v-if="!edit_telephone">
              <span> {{ form.telephone }}</span>
              <i class="el-icon-edit" @click="edit_telephone = true"></i>
            </div>
            <div v-else class="changePx">
              <el-input v-model="form.telephone"></el-input>
              <i class="el-icon-check" @click="editP(4)"></i>
            </div>
          </el-form-item>
          <el-form-item label="密码：">
            <div v-if="!edit_password">
              <span> ********</span>
              <i class="el-icon-edit" @click="edit_password = true"></i>
            </div>
            <div v-else class="changePx">
              <el-input  v-model="form.edit_password"></el-input>
              <i class="el-icon-check" @click="editP(5)"></i>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div class="tjbtn">
        <el-button type="primary">保存</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { pEditApi, pInfoApi } from "../../../api/project";

export default {
  data() {
    return {
      form: {},
      edit_company: false,
      edit_domain: false,
      edit_professional_title: false,
      edit_telephone: false,
      edit_password: false,
      edit_name: false,
    };
  },
  mounted() {
    this.form = this.$store.state.user;
    this.getPInfo();
  },
  methods: {
    //获得专家信息
    getPInfo() {
      let params = {
        token: this.$store.state.token,
      };
      let that = this;
      pInfoApi(params).then((res) => {
        console.log(res);
        that.form = Object.assign(that.form, res.data);
      });
    },

    //编辑专家信息
    editP(index) {
      let params = {
        token: this.$store.state.token,
        // name: this.user.name,
      };
      if (index == 1) {
        params.company = this.form.company;
        this.edit_company = false;
      } else if (index == 2) {
        params.domain = this.form.domain;
        this.edit_domain = false;
      } else if (index == 3) {
        params.professional_title = this.form.professional_title;
        this.edit_professional_title = false;
      } else if (index == 4) {
        params.telephone = this.form.telephone;
        this.edit_telephone = false;
      } else if (index == 5) {
        params.password = this.form.password;
        this.edit_password = false;
      } else if (index == 6) {
        params.name = this.form.name;
        this.edit_name = false;
      }
      let that = this;
      pEditApi(params).then((res) => {
        console.log(res);
        that.$message.success("更新成功");
      });
    },
  },
};
</script>
<style scoped>
.aczh .changePx .el-icon-check {
  margin-top: 40px;
}
/deep/ .el-icon-edit {
  color: #3086fb;
  font-size: 20px;
  margin-left: 15px;
}

/deep/ .el-form-item__label {
  font-size: 30px;
  font-weight: 400;
  color: #333333;
}
</style>
<style lang="scss" scoped>
.changePx {
  display: flex;
  flex-direction: row;
  .el-icon-check {
    margin-top: 10px;
    margin-left: 10px;
  }
}
.centerceont {
  width: 1100px;
  margin: 0 auto;
  padding-top: 80px;
  .acvterto {
    display: flex;
    flex-direction: row;
      .acvter {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .acavtor {
      width: 120px;
      height: 120px;
      background: #a0a0a0;
      border-radius: 10px;
    }

    .aczh {
      font-size: 36px;
      font-weight: normal;
      color: #333333;
      margin: 0 60px;
      height: 120px;
      line-height: 120px;
    }
  }
  }


  .detilrexop {
    margin: 37px 0;
    display: flex;
    justify-content: center;

    span {
      font-size: 30px;
      font-weight: 400;
      color: #333333;
    }
  }

  .tjbtn {
    display: flex;
    justify-content: center;
  }
}
</style>
